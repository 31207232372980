<template>
  <BasicModal
    cancel-title="Continue"
    :action="goToDestination"
    :action-title="actionTitle"
    :action-button-classes="['danger']"
  >
    <div class="header">
      <ErrorIcon />
      <h4>Your Patches are paused</h4>
    </div>
    <p>
      You’ve used 100% of your monthly Actions, and may be missing important
      alerts.
    </p>
    <p>
      Your Patches are paused, and you won’t be able to create any new Patches
      until the next billing cycle starts in
      <strong>{{ daysUntilUsageCycleReset }} days</strong>,
      on {{ usageCycleEndsAt }}.
    </p>
    <p v-if="userPlan.slug === 'pro'">
      Activate Pay per Action to restart your Patches now.
    </p>
    <p v-else>
      Upgrade or activate Pay per Action to restart your Patches now.
    </p>
  </BasicModal>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import ErrorIcon from '@/assets/icons/error.svg'

  export default {
    components: {
      ErrorIcon,
      BasicModal,
    },
    computed: {
      ...mapState('user', ['userPlan']),
      ...mapGetters('user', ['usageCycleEndsAt', 'daysUntilUsageCycleReset']),
      actionTitle() {
        return this.userPlan.slug === 'pro'
          ? 'Explore Pay per Action'
          : 'View plans'
      },
      actionDestination() {
        return this.userPlan.slug === 'pro'
          ? '/account/plans#pay-per-actions'
          : '/account/plans'
      },
    },
    methods: {
      goToDestination() {
        this.$router.push(this.actionDestination)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-4

    @apply flex
    @apply gap-3
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

  p + p
    @apply mt-4

</style>
